import { Link } from "react-router-dom";

import { Dropdown } from "antd";
import { useContext } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { store } from "../../Helper/Context/Store";
import "./MainNavabar.css";
export default function MainNavabarMenu() {
  const { siteHeader } = useContext(store);
  // const [show, setShow] = useState(false);
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };
  const dropdowns = siteHeader.map((item, index) => {
    const hasSubcategories =
      item.subcategories && item.subcategories.length > 0;
    const items = hasSubcategories
      ? item.subcategories.map((child) => ({
          key: child.id,
          label: (
            <Link to={`/ct${child.path}`} className="dropdown-link mx-2">
              {child.label1}
            </Link>
          ),
          children: child.subcat?.map((sub) => ({
            key: sub.id,
            label: (
              <Link to={`/ct${sub.path}`} className="dropdown-link mx-2">
                {sub.label1}
              </Link>
            ),
          })),
        }))
      : [];
    const dropdownContent = hasSubcategories ? (
      <Dropdown
        className="main-dropdown cursor-pointer navabr-menu-space"
        key={index}
        menu={{ items }}
      >
        <a
          className="ant-dropdown-link mx-2"
          onClick={(e) => e.preventDefault()}
        >
          {item.label} <IoIosArrowDown />
        </a>
      </Dropdown>
    ) : (
      <Link
        className="mx-3 cursor-pointer"
        key={index}
        to={`/ct/flash-sale/6/23`}
      >
        {item.label}
      </Link>
    );

    return dropdownContent;
  });
  return (
    <div className="border-top border-bottom">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-12">
            <ul className="d-flex main-nav-menu-links px-0 py-2 justify-content-center">
              <Link className="navabr-menu-space" to="/our-story">
                Our Story
              </Link>
              {/* {siteHeader.length > 0 ? siteHeader?.map((data, index) => {
                return (
                  <li key={`main-header${index}`}>
                    <NavDropdown
                      title={data.label}
                      id={data.id}
                      renderMenuOnMount={true}>
                      {data.subcategories?.map((subcat, ind) => {
                        return (
                          <NavDropdown.Item
                            key={`subcat-${ind}`}
                            as={Link} // Use Link as the 'as' prop
                            to={`/ct${subcat.path}`}
                            className="w-100"
                          >
                            {subcat.label1}
                          </NavDropdown.Item>
                        )
                      })}
                    </NavDropdown>
                  </li>
                )
              }) : ""} */}
              {dropdowns}
              {/* <li>
                <NavDropdown
                  title="Handcrafted Jewellery"
                  id="offcanvasNavbarDropdown-expand-sm"
                  renderMenuOnMount={true}>
                  <NavDropdown.Item>one</NavDropdown.Item>
                </NavDropdown>
              </li>
              <li>
                <NavDropdown
                  title="Semi Precious Jewellery"
                  id="offcanvasNavbarDropdown-expand-sm"
                  renderMenuOnMount={true}>
                  <NavDropdown.Item>one</NavDropdown.Item>
                </NavDropdown>
              </li>
              <li>
                <NavDropdown
                  title="Temple Jewellery"
                  id="offcanvasNavbarDropdown-expand-sm"
                  renderMenuOnMount={true}>
                  <NavDropdown.Item>one</NavDropdown.Item>
                </NavDropdown>
              </li>
              <li>
                <NavDropdown
                  title="Healing Jewellery"
                  id="offcanvasNavbarDropdown-expand-sm"
                  renderMenuOnMount={true}>
                  <NavDropdown.Item>one</NavDropdown.Item>
                </NavDropdown>
              </li> */}
              {/* <li>
                <Link to="/">Gifting Ideas</Link>
              </li> */}
              {/* <li>
                <Link to="/">Festive Collection</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
