import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { store } from "../../Helper/Context/Store";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import "./ProductCard.css";

export default function ProductCard(props) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [MRP, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const { storeStatus } = useContext(store);

  useEffect(() => {
    try {
      setItemName(props.productData.fld_variantname);
      setMRP(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[2]
      );
      setDiscountPrice(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[3]
      );
      setVariantId(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[1]
      );
      setVariantName(
        props.productData.variant_list.split("#")[0].split("$")[1].split(",")[0]
      );
      setCategoryName(props.productData.fld_categoryname);
      setVariantImage(
        props.productData.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0]
      );
    } catch {
      try {
        setItemName(props.productData.fld_variantname);
        setMRP(
          props.productData.variant_list
            .split("#")[0]
            .split("$")[1]
            .split(",")[2]
        );
        setDiscountPrice(
          props.productData.variant_list
            .split("#")[0]
            .split("$")[1]
            .split(",")[3]
        );
        setVariantId(
          props.productData.variant_list
            .split("#")[0]
            .split("$")[1]
            .split(",")[1]
        );
        setVariantName(
          props.productData.variant_list
            .split("#")[0]
            .split("$")[1]
            .split(",")[0]
        );
        setCategoryName(props.productData.fld_categoryname);
        setVariantImage(
          props.productData.variant_list
            .split("#")[0]
            .split("$")[1]
            .split(",")[7]
            .split("~")[0]
        );
      } catch {}
    }
  }, []);
  return (
    <div className="product-card">
      <Link
        to={`/productdetail/${variantName
          ?.replace(/\s/g, "-")
          .toLowerCase()}/${variantId}`}
      >
        <img alt="" src={variantImage} className="img-fluid" />
      </Link>
      <div className="content">
        <div>
          <span>{categoryName}</span>

          <p>
            <Link
              to={`/productdetail/${variantName
                ?.replace(/\s/g, "-")
                .toLowerCase()}/${variantId}`}
              className="p-0"
            >
              {/* {itemName} - */}
              {variantName}
            </Link>
          </p>

          {/* <div className="price">
            &#x20B9; {parseInt(discountPrice)}{" "}
            <del>&#x20B9; {parseInt(MRP)}</del>
          </div> */}
        </div>
        <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-lg-between align-items-lg-end">
          {/* <div to="" className="theme-btn">
            <AddToCart itemid={variantId} />
          </div> */}
          <div className="price">
            {discountPrice != 0 ? (
              <>
                &#x20B9; {parseInt(discountPrice)}{" "}
                <del>&#x20B9; {parseInt(MRP)}</del>
              </>
            ) : (
              <>&#x20B9; {parseInt(MRP)}</>
            )}
          </div>
          {storeStatus == "Active" && (
            <div className="d-flex mt-2 justify-content-lg-end align-items-end">
              {/* <p className="price mb-0 fs-5 disc-price">
                <i className="fas fa-rupee-sign"></i> ₹{" "}
                {parseFloat(discountPrice).toFixed(0)}
                {MRP > discountPrice && <del className="ms-2">₹ {MRP}</del>}
              </p> */}
              {props.isInCart ? (
                <AddToCartQty
                  itemid={variantId}
                  quantity={props.isInCart.fld_quantity}
                  cartlineid={props.isInCart.fld_cart_dtl_id}
                />
              ) : (
                // <button className="theme-btn border-0 py-1 px-2">
                <AddToCart itemid={variantId} mrp={discountPrice} />
                // </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
