import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FaListUl, FaLockOpen, FaUserEdit } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiReceiveMoney } from "react-icons/gi";
import { LuIndianRupee } from "react-icons/lu";
import { MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { store } from "../../Helper/Context/Store";
import PostApiCall from "../../Helper/PostApi";
import AddressBookForm from "../Address/AddressBookForm";
import ChangePassword from "../Login/ChangePassword";
import LogoutModal from "../Login/LogoutModal";
import CustomerOrderList from "./CustomerOrderList";
import "./CustomerProfilePage.css";
import EditProfile from "./EditProfile";
// import nodata from "../../assets/img/nodata.png";

export default function CustomerProfilePage() {
  const [apiData, setApiData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [showCanvas, setShowCanvas] = useState(false);
  const [tempAddData, setTempAddData] = useState([]);
  const [canLogout, setCanLogout] = useState(false);
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState(false);
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const { totalOrders, setcart, setLoggedIn, setcartamount } =
    useContext(store);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetProductVertical();
  }, []);
  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setApiData(obj1.data);
        }
      })
    );
  };

  useEffect(() => {
    if (showCanvas !== true) {
      getCustomerAddress();
    }
  }, [showCanvas]);

  const handleToggleCanvas = () => {
    setShowCanvas(false);
  };
  useEffect(() => {
    if (editCustomerData != true) {
      GetcustomerDatabyid();
    }
  }, [editCustomerData]);
  const GetcustomerDatabyid = () => {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setCustomerData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  };

  function getCustomerAddress() {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setAddressData(obj.data);
          Notiflix.Loading.remove();
        }
      })
    );
  }
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleCustomerProfile = () => {
    setEditCustomerData(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };

  return (
    <>
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <ChangePassword
          isOpen={openChangePwd}
          handdleToggleModal={handleTogglePwdModal}
        />
        <LogoutModal
          show={canLogout}
          onHide={handleToggleLogout}
          onLogout={onLogout}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile-card">
                {customerData[0]?.fld_created_on && (
                  <div className="d-flex pb-0 justify-content-end">
                    <p className="mb-0">
                      Customer Since: {customerData[0]?.fld_created_on}
                    </p>
                  </div>
                )}
                <div className="d-flex pt-0">
                  <div className="img">
                    <img
                      src="https://store.bechofy.in/images/Beads_Beauty/ProductImages/215493-No_Image_Available-2.webp"
                      className="img-fluid"
                      alt=""
                    />
                    {/* <div className="fields">
                      <lable>Date of Birth</lable>
                      <p>01-01-2000</p>
                    </div> */}
                  </div>
                  <div className="content">
                    <div className="fields">
                      <lable>Name</lable>
                      <p>{customerData[0]?.fld_name}</p>
                    </div>
                    {customerData[0]?.fld_dateofbirth && (
                      <div className="fields">
                        <lable>Date of Birth</lable>
                        <p>{customerData[0]?.fld_name}</p>
                      </div>
                    )}
                    <div className="fields">
                      <lable>Email</lable>
                      <p>{customerData[0]?.fld_email_address}</p>
                    </div>
                  </div>
                </div>
                <div className="btns">
                  <button
                    onClick={() => {
                      setEditCustomerData(true);
                      setTempCustomerData(customerData);
                    }}
                  >
                    <FaUserEdit /> Edit
                  </button>
                  <button onClick={() => setOpenChangePwd(true)}>
                    <FaLockOpen /> Change Password
                  </button>
                  <button onClick={() => setCanLogout(true)}>
                    <FiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row h-100">
                <div className="col-lg-6 my-4 my-lg-0">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <FaListUl />
                      </div>
                      <h2 className="main-heading mb-0">Orders</h2>
                    </div>
                    <div className="fields">
                      <lable>No. of Orders:</lable>
                      <p>{totalOrders ? totalOrders.total_order : 0}</p>
                    </div>
                    {totalOrders && (
                      <div className="fields">
                        <lable>Last Ordered on:</lable>
                        <p>{totalOrders?.fld_order_date}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <GiReceiveMoney />
                      </div>
                      <h2 className="main-heading mb-0"> My Savings</h2>
                    </div>
                    <div className="fields">
                      <lable>Spent: </lable>
                      <p>
                        <LuIndianRupee />{" "}
                        {totalOrders
                          ? parseFloat(totalOrders?.TotalSalesValue).toFixed(2)
                          : 0}
                      </p>
                    </div>
                    <div className="fields">
                      <lable>Saved: </lable>
                      <p>
                        <LuIndianRupee />{" "}
                        {totalOrders
                          ? parseFloat(totalOrders?.total_discount).toFixed(2)
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                {/* comment out when share module is required */}
                {/* <div className="col-lg-3">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <FaShare />
                      </div>
                      Share (3/10)
                    </div>
                    <div className="my-3">
                      <Input placeholder="Name" />
                    </div>
                    <div className="mb-3">
                      <Input placeholder="Mobile" />
                    </div>
                    <div className="mb-3">
                      <Input placeholder="Email" />
                    </div>
                    <button className="theme-btn border-0 px-2 py-1">
                      Submit
                    </button>
                  </div>
                </div> */}
                {/* comment out this code if required */}
                {/* <div className="col-lg-3">
                  <div className="four-card">
                    <div className="head">
                      <div className="icon">
                        <IoIosNotifications />
                      </div>
                      Notification
                    </div>
                    <div className="notification-card">
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                      <p>Explore our world of</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <CustomerOrderList />

            {/* Address Listing */}
            <div className="col-12 mb-4">
              <div className="d-flex justify-content-between mb-4 align-items-end">
                <h2 className="mb-0">Address</h2>
                <button
                  onClick={() => {
                    setShowCanvas(true);
                  }}
                  className="theme-btn border-0"
                >
                  Add Address
                </button>
              </div>

              {addressData.length === 0 ? (
                <div className="w-100 text-center">
                  {/* <img className="nodata-icon" src={nodata} /> */}
                  <p className="text-center my-2 fs-5 fw-bold">Address not found</p>
                </div>
              ) : (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {addressData.map((address, index) => {
                    return (
                      <SwiperSlide>
                        <div
                          key={index}
                          className="address-card position-relative"
                        >
                          <p>
                            {address.fld_address_1}, {address.fld_address_2},{" "}
                            <br />
                            {address.CityName}, {address.StateName} <br />{" "}
                            {address.fld_pincode}, {address.CountryName}
                          </p>
                          <p>{address.fld_landmark}</p>
                          <span
                            onClick={() => {
                              setTempAddData(address);
                              setShowCanvas(true);
                            }}
                          >
                            <MdEdit />
                          </span>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>

            {/* FBI listing */}
            {/* <div className="col-12 mb-4">
              <h2 className="mb-4">Frequently Bought Items</h2>
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper">
                {apiData.map((val) => {
                  return (
                    <SwiperSlide>
                      <div key={val.fld_variantid} className="frequently-card">
                        <ProductCard productData={val} />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div> */}
          </div>
        </div>
      </section>
      <Offcanvas
        show={showCanvas}
        onHide={handleToggleCanvas}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">
            Add New Address
          </h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <AddressBookForm
            toggleCanvas={handleToggleCanvas}
            address={tempAddData}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Canvas to edit profile data */}
      <Offcanvas
        show={editCustomerData}
        onHide={handleToggleCustomerProfile}
        className="address-offcanvas"
        id="offcanvasNavbar-expand-lg"
        aria-labelledby="offcanvasNavbarLabel-expand-sm"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <h4 className="section-title-category-section mt-0">Edit Profile</h4>
          {/* <img src={logo} className="img-fluid nav-logo" /> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <EditProfile
            toggleCanvas={handleToggleCustomerProfile}
            customerData={tempCustomerData}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
