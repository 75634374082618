import { useEffect } from "react";
import "./AboutPage.css";
import GetSeoData from "../../Helper/GetSeoData";
import banner from "../../assets/img/AboutImg/AboutUsImg.jpg";
export default function AboutPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <GetSeoData type="About Us" id={null} />
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-lg-3">
              <img src={banner} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-9">
              <h1 className="main-heading mb-2 mt-4 mt-md-1">Our Story</h1>
              <p className="fw-bold">
                Welcome to Beads & Beauty, where craftsmanship meets elegance in
                every piece we create. Founded by Anjali Sharma, a former
                professor whose passion for beauty and creativity led her on a
                remarkable journey from academia to artisanry.
              </p>
              <p>
                Anjali’s story is one of resilience and reinvention. After
                dedicating years to academia, her career trajectory took an
                unexpected turn due to health challenges. Rather than letting
                adversity dim her spirit, Anjali embraced a new path—one that
                allowed her to channel her love for aesthetics and artistry into
                something tangible and timeless.
              </p>
              <p>
                Beads & Beauty is more than just a brand; it’s a reflection of
                Anjali’s unwavering dedication to craftmanship and her belief in
                the transformative power of beauty. Each piece of handcrafted
                semi-precious jewelry is meticulously designed and curated,
                blending traditional techniques with contemporary flair.
              </p>
              <p>
                At Beads & Beauty, we celebrate individuality and expression
                through our diverse range of jewelry collections. Whether you’re
                seeking a statement piece for a special occasion or everyday
                elegance to elevate your style, we invite you to explore our
                exquisite designs crafted with passion and purpose.
              </p>
              <p>
                Join us on this journey of creativity, empowerment, and beauty.
                Experience the artistry of Beads & Beauty and discover the
                perfect adornment to complement your unique story.
              </p>
              {/* <a href="/contact-us" className="theme-btn">
                Contact Us
              </a> */}
            </div>

            {/* **** add youtube video **** */}
            <div className="col-12 mt-md-4 mt-0 text-center">
              <h1 className="main-heading mb-3 mt-0 mt-md-1">
                The Heart of the Journey
              </h1>
              <iframe
                className="w-100 viedo-iframe-size"
                src="https://www.youtube.com/embed/rmLkQOX1xxk"
                title="My Story  How I Created Beads &amp; Beauty in Gurugram"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="about-parallax-section-box my-3">
        <section
          className="parallax-section"
          style={{
            // background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${parallaxData[0]?.image.split("$")[0]?.split("#")[0]})`,
            // background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url("https://store.bechofy.in/images/Beads_Beauty/ProductImages/215493-No_Image_Available-2.webp)`,
            // height: height,
          }}>
           <h2>{parallaxData[0]?.heading}</h2>
        </section>
      </div> */}
      {/* <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 pe-lg-4">
              <img
                src="https://store.bechofy.in/images/Beads_Beauty/ProductImages/215493-No_Image_Available-2.webp"
                className="img-fluid h-50 w-100 mb-4"
              />
              <h2>Our Mission</h2>
              <p>
                Curasre potenti metus natoquefui sociis tempor facilisis tempus
                ridiaclus porta porttitor feugiat consequat aliquet maecenas
                turpis. Dui integer est lobortis fusce odio curabitur accumsan
                auctor scelerisque
              </p>
            </div>
            <div className="col-lg-4 border-end border-start px-lg-4">
              <img
                src="https://store.bechofy.in/images/Beads_Beauty/ProductImages/215493-No_Image_Available-2.webp"
                className="img-fluid h-100"
              />
            </div>
            <div className="col-lg-4 ps-lg-4">
              <h2>Our Goals</h2>
              <p className="border-bottom pb-4 mb-4">
                Metus natoquefui sociis tempor facili tempus ridiclus porta
                porttitor feugiat consequat.
              </p>
              <p className="border-bottom pb-4 mb-4">
                Metus natoquefui sociis tempor facili tempus ridiclus porta
                porttitor feugiat consequat.
              </p>
              <p>
                Metus natoquefui sociis tempor facili tempus ridiclus porta
                porttitor feugiat consequat.
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
