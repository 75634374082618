import { Skeleton } from "antd";
import React from "react";
import "./ParallaxSection.css";
export default function ParallaxSection({ parallaxData, height }) {
  return (
    <div className="parallax-section-box">
      {parallaxData.length > 0 ?
        <section className="parallax-section mb-4" style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${parallaxData[0]?.image.split("$")[0]?.split("#")[0]})`,
          height: height
        }}>
          <h1>{parallaxData[0]?.heading}</h1>
          <h2>{parallaxData[0]?.title}</h2>
          {/* Jewellery - The perfect punctuation to your personal style */}
          {/* <div className="mt-4 mt-lg-5">
              <a href={banners[0]?.slug} className="theme-btn">
                Explore More
              </a>
            </div> */}
        </section>
        :
        <div className="container-fluid parallax-section-skeleton mb-4">
          <Skeleton.Image className="w-100 h-100 img-fluid " />
        </div>}
    </div>
  );
}
