import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { IoMdMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { store } from "../../Helper/Context/Store";
import ChangePassword from "../Login/ChangePassword";
import LoginModal from "../Login/LoginModal";
import LogoutModal from "../Login/LogoutModal";
import SocialIcons from "../WrapperComponents/SocialIcons";

import "./MainNavabar.css";

export default function MainNavabar() {
  const history = useHistory();
  // const [] = useState(false)
  const {
    cart,
    setcart,
    setcartamount,
    clientData,
    loggedIn,
    setLoggedIn,
    siteHeader,
    setCartItems,
  } = useContext(store);
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [canLogout, setCanLogout] = useState(false);
  const [Offcanvas1, setOffcanvas1] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseoffcanvas = () => setOffcanvas1(false);
  const handleShowoffcanvas = () => setOffcanvas1(true);
  useEffect(() => {
    getCustomerDetails();
  }, [loggedIn]);
  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
    }
  };
  const onLogout = () => {
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      setCartItems([]);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.failure("Something went wrong");
    }
  };

  // login specific functions
  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };
  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleProfileClick = () => {
    history.push("/customer-profile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  // change password specific functions
  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleToggleLogout = () => {
    setCanLogout(false);
  };
  const handleOpenCart = () => {
    history.push("/cart");
  };

  return (
    <>
      {/* Modals for login, logout and change password */}
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />

      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      {/* main code */}
      <Navbar expand="lg" className="main-navbar py-0">
        <Container fluid>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg d-lg-none"
            onClick={handleShowoffcanvas}
          >
            <HiMiniBars3CenterLeft />
          </Navbar.Toggle>
          <Navbar.Brand href="/" className="me-auto d-flex align-items-center">
            <img alt="" src={logo} className="img-fluid nav-logo" />
            <p className="nav-heading border-start ps-3 ms-2 mb-0">
              {/* Beads & Beauty <br /> <span>  */}
              Artistry in Every Piece
              {/* </span> */}
            </p>
          </Navbar.Brand>
          <Navbar.Offcanvas
            show={Offcanvas1}
            onHide={handleCloseoffcanvas}
            className="d-lg-none main-navbar-offcanvas"
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Link to="/" onClick={handleCloseoffcanvas}>
                <img alt="" src={logo} className="img-fluid nav-logo" />
              </Link>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link
                  to="/our-story"
                  className="nav-link"
                  onClick={handleCloseoffcanvas}
                >
                  Our Story
                </Link>
                {siteHeader.length > 0
                  ? siteHeader.map((data, index) => {
                      const hasSubcategories =
                        data.subcategories && data.subcategories.length > 0;

                      return (
                        <li key={`main-header-${index}`}>
                          {hasSubcategories ? (
                            <NavDropdown
                              title={data.label}
                              id={data.id}
                              renderMenuOnMount={true}
                            >
                              {data.subcategories.map((subcat, ind) =>
                                subcat.label1 ? (
                                  <NavDropdown.Item
                                    onClick={handleCloseoffcanvas}
                                    key={`subcat-${ind}`}
                                    as={Link} // Use Link as the 'as' prop
                                    to={`/ct${subcat.path}`}
                                    className="w-100"
                                  >
                                    {subcat.label1}
                                  </NavDropdown.Item>
                                ) : null
                              )}
                            </NavDropdown>
                          ) : (
                            <Link
                              to={`/ct/flash-sale/6/23`} // Direct link for items without subcategories
                              onClick={handleCloseoffcanvas}
                              className="nav-link" // Apply any styles you need
                            >
                              {data.label}
                            </Link>
                          )}
                        </li>
                      );
                    })
                  : null}
                <Link
                  to="/contact-us"
                  className="nav-link"
                  onClick={handleCloseoffcanvas}
                >
                  Conatct Us
                </Link>
              </Nav>
              <div className="d-flex contact-links justify-content-start my-2">
                <IoCall />
                <a href={`tel:${clientData[0]?.mobile}`}>
                  {clientData[0]?.mobile}
                </a>
              </div>
              <div className="d-flex contact-links justify-content-start mb-3">
                <IoMdMail />
                <a href={`mailto:${clientData[0]?.email}`}>
                  {clientData[0]?.email}
                </a>
              </div>
              <h6>Follow us on</h6>
              <ul className="d-flex contact-links my-2 ps-0 justify-content-start">
                <SocialIcons
                  link={clientData[0]?.facebook}
                  target="_blank"
                  logo={<FaFacebookF />}
                />
                <SocialIcons
                  link={clientData[0]?.instagram}
                  target="_blank"
                  logo={<AiFillInstagram />}
                />
                <SocialIcons
                  link={clientData[0]?.twitter}
                  target="_blank"
                  logo={<FaXTwitter />}
                />
                <SocialIcons
                  link={clientData[0]?.linkedin}
                  target="_blank"
                  logo={<FaLinkedinIn />}
                />
                <SocialIcons
                  link={clientData[0]?.pintrest}
                  target="_blank"
                  logo={<FaPinterestP />}
                />
                <SocialIcons
                  link={clientData[0]?.youtube}
                  target="_blank"
                  logo={<FaYoutube />}
                />
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <ul className="main-nav-right-links contact-links d-flex">
            <li>
              <Dropdown className="login-dropdown d-none d-lg-flex">
                <Dropdown.Toggle className="login-icon" id="dropdown-basic">
                  <BiSolidUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}
                      >
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}
                      >
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="login-dropdown d-flex d-lg-none">
                <Dropdown.Toggle className="login-icon" id="dropdown-basic">
                  <BiSolidUser />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}
                      >
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}
                      >
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="cursor-pointer me-lg-2" onClick={handleOpenCart}>
              <MdShoppingCart />
              <span>{cart} Items</span>
            </li>
          </ul>
        </Container>
      </Navbar>
    </>
  );
}
