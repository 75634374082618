import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Skeleton } from "antd";
import { FaStar } from "react-icons/fa";
import { Autoplay, Pagination } from "swiper/modules";
import "./TestimonialSection.css";
export default function TestimonialSection({ testimonialData }) {
  const renderFullStars = (rating) => {
    const fullStars = [];
    for (let i = 0; i < rating; i++) {
      fullStars.push(<FaStar key={i} />);
    }
    return fullStars;
  };
  return (
    <div className="section-spacing-x section-spacing-x-mobile mt-4">
      <div className="container-fluid pb-3">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-heading">Stories of our Customers</h2>
          </div>
          <div className="col-12">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              // autoplay="flase"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper pb-4">
              {testimonialData.length > 0 ? testimonialData?.map((data, index) => {
                return (
                  <SwiperSlide key={`testimonial-${index}`}>
                    <div className="review-card">
                      <div className="img">
                        <img alt="" src={data.fld_image} className="img-fluid" />
                      </div>
                      <div className="content">
                        <p>{data.fld_name}</p>
                        <p className="feedback">
                          {data.fld_feedback}
                        </p>
                        <ul>
                          <li>
                            {renderFullStars(data.fld_rating)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }) :
                <div className="row">
                  <div className="col-lg-4">
                    <div className="d-flex">
                      <Skeleton.Image className="me-3" />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-4 d-none d-lg-block">
                    <div className="d-flex">
                      <Skeleton.Image className="me-3" />
                      <Skeleton />
                    </div>
                  </div>
                  <div className="col-4 d-none d-lg-block">
                    <div className="d-flex">
                      <Skeleton.Image className="me-3" />
                      <Skeleton />
                    </div>
                  </div>
                </div>}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
