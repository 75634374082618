import GetApiCall from "../../Helper/GetApi";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
export default function BlogsPage() {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data);
      });
    });
  }, []);
  return (
    <>
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="main-heading">Blogs</h1>
            </div>
            {blogData.length > 0
              ? blogData?.map((data, index) => {
                  return (
                    <div
                      className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                      key={`blog-${index}`}
                    >
                      <div className="blog-card">
                        <span>
                          <p>
                            {moment(data.fld_createdon, "DD/MM/YYYY").format(
                              "DD"
                            )}
                          </p>
                          <b>
                            {moment(data.fld_createdon, "DD/MM/YYYY").format(
                              "MMM"
                            )}
                          </b>
                          <b>
                            {moment(data.fld_createdon, "DD/MM/YYYY").format(
                              "YYYY"
                            )}
                          </b>
                        </span>
                        <img
                          alt=""
                          src={data.fld_blogimage}
                          className="img-fluid w-100"
                        />
                        <div className="content">
                          <p className="title">{data.fld_title}</p>
                          <p className="blog-des">
                            {data.fld_short_description}
                          </p>
                          <Link to="/" className="theme-btn">
                            Read more
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </section>
    </>
  );
}
