import React, { useContext, useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Button, message, Space } from "antd";
import Notiflix from "notiflix";
import { Dropdown, Row } from "react-bootstrap";
import { LuIndianRupee } from "react-icons/lu";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { store } from "../../Helper/Context/Store";
import PostApiCall from "../../Helper/PostApi";

export default function CustomerOrderList() {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { setTotalOrders } = useContext(store);
  const [openModalForDownloadInvoice, setOpenModalForDownloadInvoice] =
    useState(false);
  const [idToFetch, setIdToFetch] = useState("");
  const [visibleProducts, setVisibleProducts] = useState(3);
  const [paymentStatus, setPaymentStatus] = useState("finish");
  const [dispatchStatus, setDispatchStatus] = useState("wait");

  const handleShowMore = () => {
    setVisibleProducts((prevState) => prevState + 3);
  };

  useEffect(() => {
    Notiflix.Loading.init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    // function to get order details
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${parseInt(
          CustomerDetails[0].fld_customerid
        )}`,
        select: `*`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setOrderHistoryData(obj.data);
          setTotalOrders(obj.data[0]);
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const seachBarHandler = (e) => {
    setSearchInput({ ...searchInput, searchInput: e.target.value });
  };

  const toggleDownloadInvoice = (orderId) => {
    Notiflix.Loading.circle();
    setIdToFetch(orderId);
    setOpenModalForDownloadInvoice((prevStatus) => !prevStatus);
  };
  const visibleProductList = orderHistoryData.slice(0, visibleProducts);
  const items = [
    {
      label: "Price",
      key: "1",
    },
    {
      label: "name",
      key: "2",
    },
  ];

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <Row>
      <div className="col-12 my-4">
        <div className="d-flex justify-content-between align-items-end mb-4">
          <h2 className="mb-0">My Orders</h2>
          <div className="d-flex align-items-end">
            <Dropdown menu={menuProps}>
              <Button>
                <Space>
                  Sort by
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <button className="theme-btn border-0 ms-3">View All</button>
          </div>
        </div>
        {visibleProductList && visibleProductList.length > 0 ? (
          visibleProductList.map((data) => {
            return (
              <>
                <div className="order-card row mb-4">
                  <div className="col-12 d-flex justify-content-end gap-3">
                    <button className="theme-btn border-0">Track Order</button>
                    <a href="" className="theme-btn" download={true}>
                      Download Invoice
                    </a>
                    <a href="" className="theme-btn" download={true}>
                      Download Order Form
                    </a>
                  </div>
                  <div className="col-lg-3">
                    <div className="border">
                      <div className="fields">
                        <lable>Order No:</lable>
                        <p>{data.fld_order_number}</p>
                      </div>
                      <div className="fields">
                        <lable>Order Date:</lable>
                        <p>{data.fld_order_date}</p>
                      </div>
                      <div className="fields">
                        <lable>Order Type:</lable>
                        <p>{data.fld_payment_mode}</p>
                      </div>
                      <div className="fields">
                        <lable>No. of Items:</lable>
                        <p>{data.total_items}</p>
                      </div>
                      <div className="fields">
                        <lable>Amount:</lable>
                        <p>
                          <LuIndianRupee />{" "}
                          {parseFloat(data.fld_order_amount).toFixed(2)}
                        </p>
                      </div>
                      <div className="fields">
                        <lable>Payment Mode:</lable>
                        <p>{data.fld_payment_mode}</p>
                      </div>
                      <div className="fields">
                        <lable>Status:</lable>
                        <p>{data.fld_order_status}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {JSON.parse(data.item_details)?.map((dat) => {
                        return (
                          <SwiperSlide>
                            <div className="order-inner-card">
                              <img
                                src={dat.fld_imageurl}
                                className="img-fluid"
                              />
                              <div className="content">
                                <p>{dat.fld_variantname}</p>
                                <div>
                                  <span>Quantity: </span>
                                  {dat.fld_quantity}
                                </div>
                                <div>
                                  <span>Amount: </span>
                                  <LuIndianRupee />{" "}
                                  {parseFloat(
                                    dat.fld_website_discount_price
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="w-100 text-center">
            <p className="text-center my-2 my-2 fs-5 fw-bold ps-2">
              Order not found
            </p>
          </div>
        )}
      </div>
    </Row>
  );
}
