import ProductCard from "../ProductCard/ProductCard";
import "../FlashSale/FlashSale.css";
// Import Swiper React components
import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { store } from "../../Helper/Context/Store";

// import required modules
import { Skeleton } from "antd";
import { Autoplay, Pagination } from "swiper/modules";
import RightIcon from "../../assets/img/earring.png";
import LeftIcon from "../../assets/img/necklace-icon.png";
export default function FlashSale({ shaadiSpecial }) {
  const { cartItems } = useContext(store);

  return (
    <div className="section-spacing-x pt-4 pb-3 mt-4 section-spacing-x-mobile flash-sale-section">
      <img src={LeftIcon} className="left-icon img-fluid" alt="necklace" />
      <img src={RightIcon} className="right-icon img-fluid" alt="earring" />
      <div className="container-fluid pb-2 pb-lg-4" key="section-alltime-fav-1">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-heading mb-3">Shaadi special</h2>
          </div>
          <div className="col-12">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper pb-4 pb-lg-5 home-banner-slider"
            >
              {shaadiSpecial.length > 0 ? (
                shaadiSpecial?.map((data, i) => {
                  const isInCart = cartItems.find((item) => {
                    if (item.fld_variantid === data.fld_variantid) {
                      return item;
                    }
                    return false;
                  });
                  return (
                    <SwiperSlide className="flsh-sale-card">
                      <ProductCard productData={data} isInCart={isInCart} />
                    </SwiperSlide>
                  );
                })
              ) : (
                <div className="col-12">
                  <div className="row">
                    <div className={`col-md-6 col-lg-3 col-12 mb-4 mb-lg-0`}>
                      <div className="product-img-skeleton">
                        <Skeleton.Image className="h-100 w-100" />
                        <div className="buttons flex-column detailsbtn mb-0">
                          <h2 className="prod-price">
                            <Skeleton.Button />
                          </h2>
                        </div>
                        <div className="mt-3">
                          <Skeleton.Input />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-lg-3 col-12 mb-4 mb-lg-0 d-none d-lg-block`}
                    >
                      <div className="product-img-skeleton">
                        <Skeleton.Image className="h-100 w-100" />
                        <div className="buttons flex-column detailsbtn mb-0">
                          <h2 className="prod-price">
                            <Skeleton.Button />
                          </h2>
                        </div>
                        <div className="mt-3">
                          <Skeleton.Input />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0 d-none d-lg-block`}
                    >
                      <div className="product-img-skeleton">
                        <Skeleton.Image className="h-100 w-100" />
                        <div className="buttons flex-column detailsbtn mb-0">
                          <h2 className="prod-price">
                            <Skeleton.Button />
                          </h2>
                        </div>
                        <div className="mt-3">
                          <Skeleton.Input />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0 d-none d-lg-block`}
                    >
                      <div className="product-img-skeleton">
                        <Skeleton.Image className="h-100 w-100" />
                        <div className="buttons flex-column detailsbtn mb-0">
                          <h2 className="prod-price">
                            <Skeleton.Button />
                          </h2>
                        </div>
                        <div className="mt-3">
                          <Skeleton.Input />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
