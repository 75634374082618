import React from "react";
import "./Instagram.css";
import BBvideo1 from "../../assets/img/insta/BBvideo1.mp4";
import BBvideo2 from "../../assets/img/insta/BBvideo2.mp4";
import BBvideo3 from "../../assets/img/insta/BBvideo3.mp4";
import BBvideo4 from "../../assets/img/insta/BBvideo4.mp4";
import BBvideo5 from "../../assets/img/insta/BBvideo5.mp4";
import BBvideo6 from "../../assets/img/insta/BBvideo6.mp4";
import BBvideo7 from "../../assets/img/insta/BBvideo7.mp4";
import { FaInstagram } from "react-icons/fa";

export default function InstagramGrid() {
  // JSON data defined directly within the component
  const instagramData = [
    {
      url: "https://www.instagram.com/reel/DAsH1ikhdY2/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
      gif: BBvideo7,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/reel/C_KjpqAi1Fx/?utm_source=ig_web_button_share_sheet",
      gif: BBvideo2,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/beadzandbeauty/reel/C_SK1HXpGZd/",
      gif: BBvideo6,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/beadzandbeauty/reel/C_mgRooPx25/",
      gif: BBvideo1,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/beadzandbeauty/reel/C-IOGo5Pb5W/",
      gif: BBvideo4,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/beadzandbeauty/reel/DANU9PeP1ei/",
      gif: BBvideo3,
      alt: "Brads&Beauty",
    },
    {
      url: "https://www.instagram.com/beadzandbeauty/reel/C-RrCjDIesI/",
      gif: BBvideo5,
      alt: "Brads&Beauty",
    },
  ];

  return (
    <div className="container-fluid px-2 px-md-4 mt-3">
      <div className="row mx-1">
        <div className="col-12 text-center">
          <h1 className="main-heading">Trending Edition</h1>
        </div>
      </div>
      <div className="row mx-1 instagram-row">
        {instagramData.map((item, index) => (
          <div
            key={index}
            className="col-12 col-sm-4 col-lg-2  mb-0 pb-0 instagram-embed position-relative px-1 "
          >
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <video
                src={item.gif}
                autoPlay
                loop
                muted
                className="w-100 insta-video"
              />
              <div className="position-absolute instabox top-0 d-flex align-items-center justify-content-center w-100 mx-1">
                <FaInstagram className="insta-color" />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
