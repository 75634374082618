import React from "react";
import image1 from "../../assets/img/gifting-ideas/Anniversary.webp";
import image2 from "../../assets/img/gifting-ideas/BIRTHDAY.webp";
import image3 from "../../assets/img/gifting-ideas/DAILY WEAR.webp";
import image4 from "../../assets/img/gifting-ideas/LOVED ONES.webp";
import image5 from "../../assets/img/gifting-ideas/Party Wear.webp";

import "./GiftingIdeasSection.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
export default function GiftingIdeasSection() {
  return (
    <section className="gifting-ideas-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 mx-auto mb-4 text-center">
            <h2 className="main-heading">Gifting Ideas</h2>
            <p>
              Every gem tells a story, Let yours shine Life is too short to wear
              boring jewellery Traditional Jewellery - A touch of glamour, a
              dash of sophistication Handcrafted Treasures - Woven with love
              Embrace the artistry of beadwork Every gem tells a story, Let
              yours shine Life is too short to wear boring jewellery Traditional
            </p>
          </div>
          <div className="col-12">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              // autoplay="flase"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper home-banner-slider">
              <SwiperSlide>
                <img alt="" src={image1} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={image2} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={image4} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={image3} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={image5} className="img-fluid" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
