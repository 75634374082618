import { Collapse } from "antd";
import { useEffect, useState } from "react";
import GetApiCall from "../../Helper/GetApi";
import "./FaqPage.css";
import GetSeoData from "../../Helper/GetSeoData";
import FAQIm from "../../assets/img/FAQ/FAQImg.jpg";
export default function FaqPage() {
  const [faqData, setFaqData] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetFAQS").then((results) => {
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setFaqData(obj.data);
        }
      });
    });
  }, []);

  return (
    <>
      <GetSeoData type="Faq" id={null} />
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5">
              <img src={FAQIm} className="img-fluid w-100 h-100" alt="" />
            </div>
            <div className="col-lg-7 faq-accordion">
              <h3 className="mb-2 mt-md-0 mt-3 main-heading">
                We have answered few of the commonly asked questions
              </h3>
              {faqData.length ? (
                faqData?.map((data) => {
                  return (
                    <Collapse
                      accordion
                      items={[
                        {
                          key: data.fld_id,
                          label: <h6 className="mb-0">{data.fld_question}</h6>,
                          children: data.fld_answer,
                        },
                      ]}
                    ></Collapse>
                  );
                })
              ) : (
                <p className="text-center my-4">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
