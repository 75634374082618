import { Skeleton } from "antd";
import { useContext } from "react";
import { store } from "../../Helper/Context/Store";
import ProductCard from "../ProductCard/ProductCard";
import "./ExclusiveOffersSection.css";
export default function ExclusiveOffersSection({ itemsData }) {
  const { cartItems } = useContext(store);
  return (
    <div className="container-fluid pb-2 pb-lg-4">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="main-heading">Exclusive Offers</h2>
        </div>
        <div className="col-12 exclusive-offers-box">
          {itemsData.length > 0 ? (
            itemsData
              .filter((data) => data.fld_status !== "InActive")
              .map((data, index) => {
                // ?.map((data, index) => {
                const isInCart = cartItems.find((item) => {
                  if (item.fld_variantid === data.fld_variantid) {
                    return item;
                  }
                  return false;
                });
                return (
                  <div
                    className="exclusive-offers-card mb-4 mb-lg-0"
                    key={`exclusive-${index}`}
                  >
                    <ProductCard productData={data} isInCart={isInCart} />
                  </div>
                );
              })
          ) : (
            <div className="row w-100 mx-0 exclusive-offers-box">
              <div className={`exclusive-offers-card mb-4 mb-lg-0`}>
                <div className="product-img-skeleton">
                  <Skeleton.Image className="h-100 w-100" />
                  <div className="buttons flex-column detailsbtn mb-0">
                    <h2 className="prod-price">
                      <Skeleton.Button />
                    </h2>
                  </div>
                  <div className="mt-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
              <div
                className={`exclusive-offers-card mb-4 mb-lg-0 d-none d-lg-block`}
              >
                <div className="product-img-skeleton">
                  <Skeleton.Image className="h-100 w-100" />
                  <div className="buttons flex-column detailsbtn mb-0">
                    <h2 className="prod-price">
                      <Skeleton.Button />
                    </h2>
                  </div>
                  <div className="mt-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
              <div
                className={`exclusive-offers-card mb-4 mb-lg-0 d-none d-lg-block`}
              >
                <div className="product-img-skeleton">
                  <Skeleton.Image className="h-100 w-100" />
                  <div className="buttons flex-column detailsbtn mb-0">
                    <h2 className="prod-price">
                      <Skeleton.Button />
                    </h2>
                  </div>
                  <div className="mt-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
              <div
                className={`exclusive-offers-card mb-4 mb-lg-0 d-none d-lg-block`}
              >
                <div className="product-img-skeleton">
                  <Skeleton.Image className="h-100 w-100" />
                  <div className="buttons flex-column detailsbtn mb-0">
                    <h2 className="prod-price">
                      <Skeleton.Button />
                    </h2>
                  </div>
                  <div className="mt-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
              <div
                className={`exclusive-offers-card mb-4 mb-lg-0 d-none d-lg-block`}
              >
                <div className="product-img-skeleton">
                  <Skeleton.Image className="h-100 w-100" />
                  <div className="buttons flex-column detailsbtn mb-0">
                    <h2 className="prod-price">
                      <Skeleton.Button />
                    </h2>
                  </div>
                  <div className="mt-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
