import parse from "html-react-parser";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function BlogDeatailPage() {
  const location = useLocation();
  const { data } = location.state;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="section-spacing-x section-spacing-x-mobile py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="main-heading text-center mb-0">
                {data?.fld_title}
              </h1>
              <p className="text-center mt-2">
                {moment(data?.fld_createdon, "DD/MM/YYYY").format("D-MMM-YYYY")}
              </p>
              <div className="text-center">
                <img
                  src={data?.fld_blogimage}
                  className="img-fluid mx-auto mb-4"
                  alt=""
                />
              </div>
              <p>{parse(data?.fld_content)}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
