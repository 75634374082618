import Qrbg from "../../assets/img/Qr/qrbg3.jpeg";
import Clientimg from "../../assets/img/AboutImg/AboutUsImg.jpg";
import { IoCall, IoMail } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

function Qrform() {
  return (
    <div className="container-fluid p-0 m-0 d-flex justify-content-center">
      <div className="row p-0 m-0 fix-size mb-4">
        <div className="col-12 qrbg-style position-relative d-flex justify-content-center p-0 mx-0 mt-0 mb-3">
          <img src={Qrbg} className="w-100" />
          <div className="Clientimg position-absolute">
            <img src={Clientimg} className="clientimgsize" />
            <h1 className="mt-3 text-center fw-bold mb-0 pb-0">
              Anjali Sharma
            </h1>
            <h2 className="my-0 pt-0 text-center mb-0 pb-0">Founder</h2>
            <h2 className="my-0 pt-0 text-center mb-0 pb-0">Beads & Beauty</h2>
          </div>
        </div>

        <div className="col-12 mb-1 pb-3 px-3 contact-info-box   contact-details">
          <div>
            <IoCall className="icon qricon p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a href="tel:9999711383 ">+91 99997 11383 </a>
          </div>
        </div>

        <div className="col-12 mb-1 pb-3 px-3 contact-info-box   mt-2">
          <div>
            <IoMail className="icon qricon p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a href="mailto:wecare@beadsandbeauty.in">
              {" "}
              wecare@beadsandbeauty.in{" "}
            </a>
          </div>
        </div>

        <div className="col-12 mb-1 pb-3 px-3 contact-info-box   mt-2">
          <div>
            <TbWorld className="icon qricon p-2" />
          </div>
          <div className="qr-font d-flex align-items-center">
            <a href="https://beadsandbeauty.in/" target="_blank">
              www.beadsandbeauty.in
            </a>
          </div>
        </div>

        <div className="col-12 mb-1 pb-3 px-3 contact-info-box   mt-2">
          <div className="icon qricon p-2">
            <FaLocationDot className="location-qr" />
          </div>
          <div className="d-flex align-items-center">
            <a
              className="qr-font"
              href="https://maps.app.goo.gl/SnAckVGBQRsbdcQJ7"
              target="_blank"
            >
              <strong> Beads & Beauty</strong>
              <br />
              Emaar Palm Terraces Select
              <br /> Tower 10/202,
              <br /> Gurugram - 122 018, <br />
              Haryana, INDIA.
            </a>
          </div>
        </div>
        <div className="col-12 mb-5 pb-5 mb-md-0 pb-md-3 mt-3 text-center d-flex justify-content-center flex-column">
          <span className="fw-bold qr-font">Join Us</span>
          <ul className="d-flex justify-content-center mt-3 ms-0 ps-0">
            <li className="ms-0 ps-0">
              <a
                href="https://www.facebook.com/neilanjalcreations"
                target="_blank"
              >
                <FaFacebookF className="qricon p-2" />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/beadzandbeauty/?igsh=MXJmZHJxcGo0bzEzbw%3D%3D"
                target="_blank"
              >
                <AiFillInstagram className="qricon p-2" />
              </a>
            </li>

            <li>
              <a href="https://wa.me/+919999711383" target="_blank">
                <IoLogoWhatsapp className="qricon p-2" />
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 py-3 bg-white qr-btn-position">
          <Link
            to="/"
            target="blank"
            className="theme-btn w-100 text-center p-2 qr-font fw-bold"
          >
            Shop Now
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Qrform;
