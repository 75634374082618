import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { store } from "../../Helper/Context/Store";
import PostApiCall from "../../Helper/PostApi";
import LoginModal from "../Login/LoginModal";
// import "./AddToCart.css";

function AddToCart({ itemid, mrp }) {
  const { setcart, setCartItems } = useContext(store);
  const [isOpen, setIsOpen] = useState(false);
  const [modalSide, setModalSide] = useState("Log");

  const handleToggleModal = () => {
    setIsOpen(false);
    setModalSide("Log");
  };

  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };

  const GetCartdetails = () => {
    var Order = localStorage.getItem("OrderData");
    var OrderData = JSON.parse(Order);
    var orderid = 0;
    if (localStorage.getItem("OrderData") !== null) {
      orderid = OrderData[0].order_id;
    }
    if (localStorage.getItem("CustomerData") === null) {
    } else {
      var login = localStorage.getItem("CustomerData");
      var logindetails = JSON.parse(login);
      if (localStorage.getItem("OrderData") !== null) {
        PostApiCall.postRequest(
          {
            orderid:
              localStorage.getItem("OrderData") == null
                ? null
                : OrderData[0].order_id,
            id:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
          },
          "GetCartWebsite"
        )
          .then((res) => res.json())
          .then((res1) => {
            if (res1.data) {
              setcart(res1.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
              setCartItems(res1.data);
            }
          });
      }
    }
  };

  const addtocart = () => {
    Notiflix.Loading.dots("");
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var OrderData = JSON.parse(Order);
    if (logindetails === null) {
      localStorage.setItem(
        "itemdetails",
        JSON.stringify([
          {
            Item: `${itemid}`,
            Quantity: `${1}`,
          },
        ])
      );
      setIsOpen(true); // open login modal
      Notiflix.Loading.remove();
    } else {
      // ===========with Login=========
      PostApiCall.postRequest(
        {
          orderdate: moment().format("lll"),
          itemdetails: `
                     [
                     {
                         "Item":${itemid},
                         "Quantity":${1}
                     }
                     ]
                     `,
          status: "INCART",
          customerid:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_customerid,
          createdon: moment().format("lll"),
          updatedon: moment().format("lll"),
          orderid:
            localStorage.getItem("OrderData") == null
              ? 0
              : OrderData[0].order_id,
          updatedby:
            localStorage.getItem("CustomerData") == null
              ? null
              : logindetails[0].fld_userid,
        },
        "AddToCartWebsite"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status === 200 || result.status === 201) {
            // Notiflix.Notify.Success("Product added to Cart.");
            // Notiflix.Loading.Remove();
            if (localStorage.getItem("OrderData") == null) {
              localStorage.setItem("OrderData", JSON.stringify(obj.data));
              Notiflix.Loading.remove();
            } else {
              Notiflix.Loading.remove();
            }
            GetCartdetails();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(obj.data);
          }
        })
      );
    }
  };

  return (
    <>
      <LoginModal
        isOpen={isOpen}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      {mrp > 0 ? (
        <button className="theme-btn border-0 py-1 px-2">
          <div className="text-white add-btn-mobile" onClick={addtocart}>
            Add to Cart
          </div>
        </button>
      ) : (
        <button className="border-0 py-1 px-2 sold-out-btn">
          <div className="add-btn-mobile">Sold Out</div>
        </button>
      )}
    </>
  );
}
export default AddToCart;
