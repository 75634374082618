import React, { useContext, useEffect, useState } from "react";
import "./AllCategorySection.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import PostApiCall from "../../Helper/PostApi";

import { Skeleton } from "antd";
import { Link } from "react-router-dom";
import { store } from "../../Helper/Context/Store";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";

export default function AllCategorySection() {
  const [vertical, setVertical] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState("");
  const { storeStatus, cartItems } = useContext(store);
  useEffect(() => {
    GetProductVertical();
  }, []);
  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setVertical(obj1.data);
        }
      })
    );
  };
  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.realIndex;
    if (vertical.length > currentSlideIndex) {
      setSelectedVertical(vertical[currentSlideIndex].fld_verticlename);
    }
  };
  return (
    <section className="pt-2 pt-lg-4 pb-lg-2 mb-3 section-spacing-x">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-heading">
              Explore our world of {selectedVertical}
            </h2>
          </div>
          <div className="col-12 category-padding-main">
            <Swiper
              spaceBetween={5}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              onSlideChange={handleSlideChange}
              className="mySwiper all-category-slider col-12 pb-4 pb-lg-5 swiper-h"
            >
              {vertical.length > 0 ? (
                vertical?.map((data, index) => {
                  return (
                    <SwiperSlide key={`AllCat${index}`}>
                      <div className="row mx-0">
                        <div className="col-lg-4 px-0">
                          <img
                            alt=""
                            src={data.fld_verticalImage}
                            className="img-fluid w-100"
                          />
                        </div>
                        <div className="col-lg-8 px-lg-0 all-category-img">
                          <div className="row h-100 mx-0">
                            {data.variant_list &&
                              JSON.parse(data.variant_list)[0]
                                ?.Products?.sort(() => Math.random() - 0.5)
                                ?.slice(0, 3)
                                .map((item, idx) => {
                                  const isInCart = cartItems.find((cartItm) => {
                                    if (
                                      cartItm.fld_variantid === item?.VariantID
                                    ) {
                                      return item;
                                    }
                                    return false;
                                  });
                                  return (
                                    <div
                                      div
                                      key={`item_${idx}`}
                                      className=" col-4 pe-0 mb-2 mb-lg-0 position-relative allcatergory-card overflow-y-hidden"
                                    >
                                      <Link
                                        to={`/productdetail/${item.VariantName?.replace(
                                          /\s/g,
                                          "-"
                                        ).toLowerCase()}/${item.VariantID}`}
                                        className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right"
                                      >
                                        <img
                                          alt=""
                                          src={item.ImageURL}
                                          className="img-fluid w-100 h-100"
                                        />
                                      </Link>
                                      {storeStatus === "Active" && (
                                        <div className="category-add-cart-btn">
                                          <div className="text-start">
                                            <Link
                                              to={`/productdetail/${item.VariantName?.replace(
                                                /\s/g,
                                                "-"
                                              ).toLowerCase()}/${
                                                item.VariantID
                                              }`}
                                            >
                                              <p className="text-white">
                                                {item.VariantName}
                                              </p>
                                            </Link>
                                            <p className="disc-price text-white">
                                              ₹{" "}
                                              {parseFloat(
                                                item.SellingPrice
                                              ).toFixed(0)}
                                              {item.Mrp !=
                                                item.SellingPrice && (
                                                <del className="ms-2">
                                                  ₹{" "}
                                                  {parseFloat(item.Mrp).toFixed(
                                                    0
                                                  )}
                                                </del>
                                              )}
                                            </p>
                                          </div>
                                          {isInCart ? (
                                            <AddToCartQty
                                              itemid={item.VariantID}
                                              quantity={isInCart.fld_quantity}
                                              cartlineid={
                                                isInCart.fld_cart_dtl_id
                                              }
                                            />
                                          ) : (
                                            <span className="cursor-pointer">
                                              <AddToCart
                                                itemid={item.VariantID}
                                                mrp={item.SellingPrice}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              ) : (
                <div className="row mx-0 all-category-skeleton">
                  <div className="col-lg-4 px-0">
                    <div className="all-category-card">
                      <Skeleton.Image className="w-100 img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-8 px-lg-0 all-category-img">
                    <div className="row h-100 mx-0">
                      <div
                        key="1"
                        className=" col-4 pe-0 mb-2 mb-lg-0 position-relative allcatergory-card overflow-y-hidden"
                      >
                        <Skeleton.Image className="w-100 img-fluid" />
                      </div>
                      <div
                        key="2"
                        className=" col-4 pe-0 mb-2 mb-lg-0 position-relative allcatergory-card overflow-y-hidden"
                      >
                        <Skeleton.Image className="w-100 img-fluid" />
                      </div>
                      <div
                        key="3"
                        className=" col-4 pe-0 mb-2 mb-lg-0 position-relative allcatergory-card overflow-y-hidden"
                      >
                        <Skeleton.Image className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
