import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { store } from "../../Helper/Context/Store";
import GetApiCall from "../../Helper/GetApi";
import PostApiCall from "../../Helper/PostApi";
import MainNavabar from "./MainNavabar";
import MainNavabarMenu from "./MainNavabarMenu";

export default function FixedNavbar() {
  const [scroll, setScroll] = useState(false);
  const { setSiteHeader, setLogo, setClientData, setCartItems, setcart, setStoreStatus } = useContext(store);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
    GetSiteSettingsV1();
    getCartdetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetSiteSettingsV1 = () => {
    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          setClientData(obj1.data);
          setSiteHeader(JSON.parse(obj1.data[0]?.site_header)?.menu[0]?.subMenu)
          setStoreStatus(obj1.data[0].storeStatus);
        }
      })
    );
  }
  const getCartdetails = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          setCartItems(res1.data)
          Notiflix.Loading.remove();
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          // setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };
  return (
    <div className={scroll ? "fixed-top fixed-nammenu" : ""}>
      <MainNavabar />
      <MainNavabarMenu />
    </div>
  );
}
