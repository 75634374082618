import { useEffect, useState } from "react";
import AllCategorySection from "../Components/AllCategorySection/AllCategorySection";
import AllTimeFavouritesSection from "../Components/AllTimeFavouritesSection/AllTimeFavouritesSection";
import BlogsSection from "../Components/BlogsSection/BlogsSection";
import ExclusiveOffersSection from "../Components/ExclusiveOffersSection/ExclusiveOffersSection";
import FooterPolicySection from "../Components/FooterPolicySection/FooterPolicySection";
import GiftingIdeasSection from "../Components/GiftingIdeasSection/GiftingIdeasSection";
import HomeBanner from "../Components/HomeBanner/HomeBanner";
import ParallaxSection from "../Components/ParallaxSection/ParallaxSection";
import TestimonialSection from "../Components/TestimonialSection/TestimonialSection";
// import VideoSection from "../Components/VideoSection/VideoSection";
import GetApiCall from "../Helper/GetApi";
import PostApiCall from "../Helper/PostApi";
import GetSeoData from "../Helper/GetSeoData";
import Teejofferimg from "../assets/img/popupofferimg/get5off.png";

// Add Instagram link
import InstagramGrid from "../Components/Instavideo/InstaLinks";
import { Button, Form, Modal } from "react-bootstrap";
import FlashSale from "../Components/FlashSale/FlashSale";
import ShaadiSpecial from "../Components/ShaadiSpecial/ShaadiSpecial";

export default function Home() {
  const [mainBanner, setMainBanner] = useState([]);
  // const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [exclusiveOffersReverse, setExclusiveOffersReverse] = useState([]);
  const [parallax1Banner, setParallax1Banner] = useState([]);
  const [parallax2Banner, setParallax2Banner] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [websiteTestimonialData, setWebsiteTestimonialData] = useState([]);
  const [flashSaleData, setFlashSaleData] = useState([]);
  const [karwaChauth, setKarwaChauth] = useState([]);
  const [modalShow, SetModalShow] = useState(false);

  const handleClose = () => SetModalShow(false);
  const handleShow = () => SetModalShow(true);

  // Function to shuffle array items
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // select random items
  const selectRandomItems = (items, count) => {
    const activeItems = items.filter((item) => item.fld_status === "Active");
    const shuffledItems = shuffleArray(activeItems);
    return shuffledItems.slice(0, count);
  };

  useEffect(() => {
    getBanners();
    GetBlogArticle();
    GetTestimonial();

    GetApiCall.getRequest("GetHomepageSetup").then((resultdes) =>
      resultdes.json().then((obj) => {
        // obj.data
        //   .filter((value) => value.fld_section_name === "Featured Products")
        //   .forEach((filtereddata) => {
        //     PostApiCall.postRequest(
        //       {
        //         sectionname: filtereddata.fld_section_name,
        //         itemchoice: filtereddata.fld_item_choice,
        //       },
        //       "GetWebsiteHomePageItems"
        //     ).then((results) => {
        //       if (results.status === 200 || results.status === 201) {
        //         results.json().then((data) => {
        //           setBestSeller(data.data);
        //         });
        //       }
        //     });
        //   });
        // obj.data
        //   .filter((value) => value.fld_section_name === "Exclusive Offers")
        //   .forEach((filtereddata) => {
        //     PostApiCall.postRequest(
        //       {
        //         sectionname: filtereddata.fld_section_name,
        //         itemchoice: filtereddata.fld_item_choice,
        //       },
        //       "GetWebsiteHomePageItems"
        //     ).then((results) => {
        //       results.json()?.then((obj) => {
        //         if (results.status === 200 || results.status === 201) {
        //           setExclusiveOffers(obj.data);
        //         }
        //       });
        //     });
        //   });

        // For flash sale
        obj.data
          .filter((value) => value.fld_section_name === "Flash Sale")
          .forEach((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status === 200 || results.status === 201) {
                  setFlashSaleData(obj.data);
                }
              });
            });
          });
      })
    );

    // APi for all category
    PostApiCall.postRequest(
      {
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) => {
      if (results.status === 200 || results.status === 201) {
        results.json().then((data) => {
          const fetchedData = data.data;
          setBestSeller(selectRandomItems(fetchedData, 4));
          setExclusiveOffersReverse(selectRandomItems(fetchedData, 5));
        });
      }
    });

    // For karwa chauth
    PostApiCall.postRequest(
      {
        categoryid: 24,
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) => {
      if (results.status === 200 || results.status === 201) {
        results.json().then((data) => {
          setKarwaChauth(data.data);
        });
      }
    });
  }, []);

  //Show random reverse data for exclusive offers
  const reverseItems = (items) => {
    return [...items].reverse();
  };

  const exclusiveOffers = reverseItems(exclusiveOffersReverse);

  const GetTestimonial = () => {
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetTestimonial"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWebsiteTestimonialData(obj.data);
        }
      });
    });
  };

  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data);
      });
    });
  };

  const getBanners = () => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          var Parallax1Banner = obj1.data.filter(
            (item) => item.location === "Parallax"
          );
          var Parallax2Banner = obj1.data.filter(
            (item) => item.location === "Parallax2"
          );
          setMainBanner(filterBanner);
          setParallax1Banner(Parallax1Banner);
          setParallax2Banner(Parallax2Banner);
        }
      })
    );
  };

  // ***** For open popup ******

  window.onload = () => {
    if (
      localStorage.getItem("show_popup") == null ||
      localStorage.getItem("show_popup") == undefined ||
      localStorage.getItem("show_popup") == "yes"
    ) {
      SetModalShow(true);
      localStorage.setItem("show_popup", "yes");
    }
  };

  return (
    <>
      <GetSeoData type="Home" id={null} />
      <HomeBanner banners={mainBanner} />
      <ShaadiSpecial shaadiSpecial={karwaChauth} />
      {/* <FlashSale flashSaleData={flashSaleData} /> */}
      <AllCategorySection />
      <AllTimeFavouritesSection itemsData={bestSeller} />
      <ParallaxSection parallaxData={parallax1Banner} height="40vh" />
      <ExclusiveOffersSection itemsData={exclusiveOffers} />
      <GiftingIdeasSection />
      <TestimonialSection testimonialData={websiteTestimonialData} />
      <ParallaxSection parallaxData={parallax2Banner} height="30vh" />
      <BlogsSection blogData={blogData} />
      <InstagramGrid />
      <FooterPolicySection />

      {/* ****** For Poup window onload ******  */}
      <Modal
        show={modalShow}
        onHide={() => SetModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="md"
      >
        <Modal.Header className="position-absolute cross-btn-custom right-0 py-0 my-0 ps-0 border-0 z-2">
          <span
            className="text-white fs-1 cursor-pointer"
            onClick={handleClose}
          >
            ×
          </span>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="add-modal">
            <img src={Teejofferimg} alt="Get15% OFF" className="w-100" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
