import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import { Skeleton } from "antd";
import "./HomeBanner.css";
export default function HomeBanner({ banners }) {
  return (
    <>
      {banners.length > 0 ? (
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper home-banner-slider"
        >
          {banners.length > 0
            ? banners?.map((data, index) => {
                return (
                  <SwiperSlide key={`Homebanne${index}`}>
                    <img
                      alt=""
                      src={data.image.split("$")[0].split("#")[0]}
                      className="img-fluid"
                    />
                  </SwiperSlide>
                );
              })
            : ""}
        </Swiper>
      ) : (
        <div className="main-slider-skeleton">
          <Skeleton.Image className="w-100 img-fluid home-slider-skeleton" />
        </div>
      )}
    </>
  );
}
