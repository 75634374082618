import { Skeleton } from "antd";
import { useContext } from "react";
import { store } from "../../Helper/Context/Store";
import ProductCard from "../ProductCard/ProductCard";
import "./AllTimeFavouritesSection.css";

export default function AllTimeFavouritesSection({ itemsData }) {
  const { cartItems } = useContext(store);

  return (
    <div className="section-spacing-x section-spacing-x-mobile">
      <div className="container-fluid pb-2 pb-lg-4" key="section-alltime-fav-1">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-heading">
              All time Favourites - Curated with you in mind
            </h2>
          </div>
          {itemsData.length > 0 ? (
            itemsData.filter((data) => data.fld_status !== "InActive")
            .map((data, i) => {
              const isInCart = cartItems.find((item) => {
                if (item.fld_variantid === data.fld_variantid) {
                  return item;
                }
                return false;
              });
              return (
                <div
                  className="col-6 col-lg-3 mb-4 mb-lg-0"
                  key={`alltime${i}`}
                >
                  <ProductCard productData={data} isInCart={isInCart} />
                </div>
              );
            })
          ) : (
            <div className="col-12">
              <div className="row">
                <div className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0`}>
                  <div className="product-img-skeleton">
                    <Skeleton.Image className="h-100 w-100" />
                    <div className="buttons flex-column detailsbtn mb-0">
                      <h2 className="prod-price">
                        <Skeleton.Button />
                      </h2>
                    </div>
                    <div className="mt-3">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
                <div className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0`}>
                  <div className="product-img-skeleton">
                    <Skeleton.Image className="h-100 w-100" />
                    <div className="buttons flex-column detailsbtn mb-0">
                      <h2 className="prod-price">
                        <Skeleton.Button />
                      </h2>
                    </div>
                    <div className="mt-3">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
                <div className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0`}>
                  <div className="product-img-skeleton">
                    <Skeleton.Image className="h-100 w-100" />
                    <div className="buttons flex-column detailsbtn mb-0">
                      <h2 className="prod-price">
                        <Skeleton.Button />
                      </h2>
                    </div>
                    <div className="mt-3">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
                <div className={`col-md-6 col-lg-3 col-6 mb-4 mb-lg-0`}>
                  <div className="product-img-skeleton">
                    <Skeleton.Image className="h-100 w-100" />
                    <div className="buttons flex-column detailsbtn mb-0">
                      <h2 className="prod-price">
                        <Skeleton.Button />
                      </h2>
                    </div>
                    <div className="mt-3">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
