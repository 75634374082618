import React from "react";
import { Link } from "react-router-dom";
import PageNotFoundImage from "../../assets/img/BndErrorLogo.png";

const PageNotFound = () => {
	return (
		<>
			<div>
				<div className="container">
					<div className="row blank-section mt-5 marginbtm-240">
						<div className="col-md-12">
							<div className="four-o-four mt-4">
								<div className="d-flex justify-content-center">
									<img
										src={PageNotFoundImage}
										className="paymentFail img-fluid h-50"
										alt="Page not found"
										height="550px"
										width="550px"
									></img>
								</div>
								<div
									className="center-block mt-3"
									style={{ display: "grid", justifyContent: "center", textAlign: "center" }}
								>
									<p>The page you are looking for is not found.</p>
									<h1>Something's missing.</h1>
									<div className="d-flex justify-content-center mb-5">
										<Link to="/" className="theme-btn">
											Go to homepage
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default PageNotFound;
